import Vue from 'vue';
import VueI18n from 'vue-i18n';

// For country codes: https://www.iso.org/obp/ui/#search/code/
import en from '../locales/en.json';
import dk from '../locales/dk.json';

Vue.use(VueI18n);

const messages = {
  en,
  dk,
};

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
});

export default i18n;
