import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/landing',
    name: 'landing',
    component: () => import('@/views/LandingPageView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    meta: { requiresUnAuth: true },
    component: () => import('@/views/LoginView.vue'),
  },
  {
    path: '/register',
    name: 'register',
    meta: { requiresUnAuth: true },
    component: () => import('@/views/RegisterAccountView.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: { requiresUnAuth: true },
    component: () => import('@/views/ForgotPasswordView.vue'),
  },
  {
    path: '/reset-password/:resetToken',
    name: 'reset-password',
    meta: { requiresUnAuth: true },
    component: () => import('@/views/ResetPasswordView.vue'),
    props: true,
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('@/views/HelpView.vue'),
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'database',
        name: 'database',
        component: () => import('@/views/MarketplaceView.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'material/:materialId',
        name: 'single-material',
        component: () => import('@/views/SingleMaterialView.vue'),
        meta: { requiresAuth: true },
        props: true,
      },
      {
        path: 'messages',
        name: 'messages',
        component: () => import('@/views/ChatView.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'companies/:id',
        name: 'public-company',
        component: () => import('@/views/PublicCompanyView.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'users/:id',
        name: 'public-user',
        component: () => import('@/views/PublicUserView.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'account',
        name: 'account',
        component: () => import('@/views/AccountView.vue'),
        meta: { requiresAuth: true },
        children: [
          {
            path: 'personal-info',
            name: 'personal-info',
            component: () => import('@/views/PersonalInfoView.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'manager-panel',
            name: 'manager-panel',
            component: () => import('@/views/ManagerPanelView.vue'),
            meta: { requiresAuth: true, requiresManager: true },
          },
          {
            path: 'manager-panel/offerings',
            name: 'offerings',
            component: () => import('@/views/ManagerPanelView.vue'),
            meta: { requiresAuth: true, requiresManager: true },
            props: { initialOption: 'offerings' },
          },
          {
            path: 'manager-panel/user-management',
            name: 'user-management',
            component: () => import('@/views/ManagerPanelView.vue'),
            meta: { requiresAuth: true, requiresManager: true },
            props: { initialOption: 'user-management' },
          },
          {
            path: 'dashboard',
            name: 'company-dashboard',
            component: () => import('@/views/dashboard/CompanyDashboardView.vue'),
            meta: { requiresAuth: true },
          },
          {
            path: 'pending-offers',
            name: 'pending-offers',
            component: () => import('@/views/dashboard/DashboardOffersView.vue'),
            meta: { requiresAuth: true },
            props: { initialOption: 'pending-offers' },
          },
          {
            path: 'synergies',
            name: 'synergies',
            component: () => import('@/views/dashboard/DashboardOffersView.vue'),
            meta: { requiresAuth: true },
            props: { initialOption: 'synergies' },
          },
        ],
      },
    ],
  },
  {
    path: '/admin',
    name: 'admin',
    meta: { requiresUnAuth: true, requiresAdminUnAuth: true },
    component: () => import('@/views/AdminView.vue'),
    children: [
      {
        path: 'login',
        name: 'admin-login',
        meta: { requiresAdminUnAuth: true },
        component: () => import('@/components/admin/AdminLogin.vue'),
      },
      {
        path: 'panel',
        name: 'admin-panel',
        meta: { requiresAdminAuth: true },
        component: () => import('@/components/admin/AdminPanel.vue'),
      },
      {
        path: 'users',
        name: 'admin-user-management',
        meta: { requiresAdminAuth: true },
        component: () => import('@/components/admin/users/UserManagement.vue'),
      },
      {
        path: 'offerings',
        name: 'admin-offerings',
        meta: { requiresAdminAuth: true },
        component: () => import('@/components/admin/offerings/OfferingsManagement.vue'),
      },
    ],
  },
];

const liveRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

liveRouter.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated'];
  const isAdmin = store.getters['auth/isAdmin'];
  const userRole = store.getters['auth/getUserRole'];

  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ path: '/login', query: { redirect: to.fullPath } });
  } else if (to.meta.requiresAuth && isAuthenticated && !isAdmin && to.meta.requiresManager && userRole !== 'MANAGER') {
    next(from.path);
  } else if (to.meta.requiresAuth && isAuthenticated && isAdmin) {
    next('/admin/panel');
  } else if (to.meta.requiresUnAuth && isAuthenticated) {
    next(isAdmin ? from.path : { path: '/login', query: { redirect: to.fullPath } });
  } else if (to.meta.requiresAdminAuth && (!isAuthenticated || !isAdmin)) {
    next(from.path);
  } else if (to.meta.requiresAdminUnAuth && isAdmin) {
    next('/admin/panel');
  } else if (to.matched.length === 0) {
    next('/');
  } else {
    next();
  }
});

export default liveRouter;
